import { Link } from "gatsby";
import React from "react";
import { timeDiffString } from "../../utils/format";

export type UebersichtEntryDataType = {
  mandantName: string;
  mandantNumber: string;
  numBelege: number;
  id: string;
  createdAt: Date;
};

type PropType = {
  data: UebersichtEntryDataType;
};

export default function UebersichtEntry({ data }: PropType) {
  return (
    <div className="uebersicht-entry">
      <Link to={`/filecontent/${data.id}`} className="plain-link">
        <div className="flex">
          <span className="flex-none w-20 align-middle text-center text-gray-600">{data.mandantNumber}</span>
          <span className="flex-1 pl-2">{data.mandantName}</span>
        </div>
        <div className="flex mt-2">
          <span className="text-xs w-20 text-right flex-none">{`${data.numBelege} Belege`}</span>{" "}
          <span className="text-xs flex-1 text-right text-gray-600">{`erstellt vor ${timeDiffString(data.createdAt)}`}</span>
        </div>
      </Link>
    </div>
  );
}
