import _ from "lodash";

export const moneyFormat = (number: number) => {
  return number.toFixed(2) + "€";
};

export const dateToISoDateString = (date: Date | null | undefined) => {
  return date ? date.toISOString().split("T")[0] : null;
};

export const timeDiffString = (dateFirst: Date, dateSecond?: Date) => {
  dateSecond = dateSecond || new Date();
  const diffInMin = _.floor((dateSecond.getTime() - dateFirst.getTime()) / 60000);
  if (diffInMin <= 0) {
    return "< 1 Min";
  }
  if (diffInMin < 60) {
    return `${diffInMin} Min`;
  }
  const diffInHours = _.floor(diffInMin / 60);
  if (diffInHours < 24) {
    return `${diffInHours} Stunde${diffInHours > 1 && "n"}`;
  }
  const diffInDays = _.floor(diffInHours / 24);
  return `${diffInDays} Tag${diffInDays > 1 && "e"}`;
};
