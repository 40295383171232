import { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import UebersichtEntry, { UebersichtEntryDataType } from "../components/uebersicht/UebersichtEntry";
import WaitingAnimation from "../components/WaitingAnimation";
import { addAxiosError, addError, useNotificationStore } from "../utils/errors";
import { authenticationService } from "../_services/authenticationService";
import { urlService } from "../_services/urlService";

// markup
const Page = () => {
  const [entries, setEntries] = useState(null as UebersichtEntryDataType[] | null);
  const [isLoggedIn, setIsLoggedIn] = useState(authenticationService.isLoggedIn()); // We assume true

  useEffect(() => {
    if (entries !== null) {
      return;
    }
    const url = urlService.getUrl("/filecontent/");
    urlService
      .getAxios()
      .get(url)
      .then((res) => {
        if (res.data) {
          // Set the beleg-date for all buchungen
          const entries = res.data.entries.map(
            (entry: any) =>
              ({
                mandantName: entry.mandant.name,
                mandantNumber: entry.mandant.number,
                numBelege: entry.numBelege,
                id: entry.id,
                createdAt: new Date(entry.createdAt),
              } as UebersichtEntryDataType)
          );

          setEntries(entries);
        } else {
          addError("Die Daten vom Server konnten nicht abgerufen werden");
        }
      })
      .catch((err: AxiosError) => {
        // Make the waiting animation disappear
        setEntries([]);
        if (err.response?.status === 401) {
          console.log("401");
          return;
        }
        console.log(`Other error. ${JSON.stringify(err)}`);
        console.log(err);
        addAxiosError(err);
      });
  }, [entries]);

  return (
    <div>
      <h2>Übersicht</h2>
      <div className="text-center">
        {entries === null && <WaitingAnimation />}
        {entries?.length === 0 &&
          (isLoggedIn ? (
            <div>
              <span className="empty-notification block">Sie haben derzeit keine hochgeladenen Dateien</span>
              <span className="text-xs text-gray-800">Alle Inhalte werden nach einer Stunde gelöscht</span>
            </div>
          ) : (
            <div>
              <span className="empty-notification block">Bitte loggen Sie sich ein, um diese Funktion nutzen zu können.</span>
            </div>
          ))}
      </div>
      <TransitionGroup className="w-full flex flex-row flex-wrap">
        {entries !== null &&
          entries.length > 0 &&
          entries.map((entry) => (
            <CSSTransition key={entry.id} timeout={500} classNames="item">
              <UebersichtEntry data={entry} />
            </CSSTransition>
          ))}
      </TransitionGroup>
    </div>
  );
};

export default Page;
